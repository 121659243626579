import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { listaDeVendas } from "@/api/relatorios/relatorio_vendas";
import { format, parseISO } from "date-fns";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtros = bodyPDF.queryString;
  let items = await listaDeVendas(filtros);
  let items_info = items.data;
  const filtros_info = items.filtros;
  const agrupamento = items.agrupamento;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value){ 
      text_filtros += key.toUpperCase() + ": " + value + "   ";
    }
  }
  const cabecalho = bodyPDF.cabecalho;
  const group_vendas_value = agrupamento;

  const periodo = bodyPDF.periodo;

  return new Promise((resolve, reject) => {
    let cabecalho_data = {
      margin: [0, 5, 0, 10],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: " CONTAS A RECEBER - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
    };
    
    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
    };

    
    const arrayItensAgrupados = [];
    const map = new Map();

    items_info.forEach(item => {
      const { [group_vendas_value] : valor } = item;
      if (!map.has(valor)) {
        map.set(valor, arrayItensAgrupados.length);
        arrayItensAgrupados.push([item]);
      } else {
        arrayItensAgrupados[map.get(valor)].push(item);
      }
    });

    let final_array = [];

    for (const table_info of arrayItensAgrupados) {

      final_array.push({
        margin:[0, 0, 0, -1],
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: group_vendas_value.toUpperCase() + ": " + table_info[0][group_vendas_value],
                fillColor: "#E0E0E0",
                border: [true, true, true, false],
                fontSize: 8,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
        },
      });

      let table_vendas = {
        margin: [0, 0, 0, 5],
        table: {
          widths: [20, "*", 45, "*", 40, 35, 50, 50, 40, 35],
          body: [
            [
              {
                text: "COD",
                border: [true, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "CLIENTE",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "DATA",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "VENDEDOR",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "DEPOSITO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "MOEDA",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "VALOR",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "CUSTO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "LUCRO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "MARGEM",
                border: [false, true, true, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      };

      const row_font_size = 8;
      table_info.forEach((item, index) => {
        // if item doest not have a key value named filtros
        if (!item.filtros) {
          let table_row = [
            {
              text: item.venda_id,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                true,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
    
            {
              text: Vue.filter("excerpt")(item.cliente, 15),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
    
            {
              text: format(parseISO(item.data), "dd/MM/yyyy"),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: Vue.filter("excerpt")(item.vendedor, 15),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:item.deposito_id + " - " + Vue.filter("excerpt")(item.deposito, 15),            
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: item.sigla,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: (
                  item.sigla == "G$"
                    ? Vue.filter("guarani")(item.valor)
                    : Vue.filter("currency")(item.valor)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:(
                  item.sigla == "G$"
                    ? Vue.filter("guarani")(item.custo)
                    : Vue.filter("currency")(item.custo)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:(
                  item.sigla == "G$"
                    ? Vue.filter("guarani")(item.lucro)
                    : Vue.filter("currency")(item.lucro)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:(
                  item.sigla == "G$"
                    ? Vue.filter("guarani")(item.margem)
                    : Vue.filter("currency")(item.margem)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                true,
                index != table_info.length - 1 ? false : true,
              ],
            },
          ];
    
          table_vendas.table.body.push(table_row);
        }
      }); 


      final_array.push(table_vendas);
    }

    let content = [cabecalho, cabecalho_data, final_array];
    if (!Object.values(filtros_info).every(value => value === null)) {
      content.splice(1, 0, cabecalho_filtros);
    }

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
